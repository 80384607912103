import "./App.css"

function App() {
  return (
    <div className="App mx-10 my-12 max-w-sm">
      <p className="mb-2">
        <span className="font-bold text-xl">raymond</span>
      </p>
      <p>Hi!</p>
      <p>I’m a researcher and software engineer.</p>
      <p>
        I work on{" "}
        <a target="_blank" href="https://canvas.xyz" rel="noreferrer">
          Canvas
        </a>
        , a peer-to-peer technology company.
      </p>
      <p>
        In the past, I was a co-founder at{" "}
        <a target="_blank" href="https://commonwealth.im" rel="noreferrer">
          Commonwealth
        </a>
        , building governance tools for crypto networks.
      </p>
    </div>
  )
}

export default App
